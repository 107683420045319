define("discourse/plugins/discourse-subscriptions/discourse/components/product-item", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2>{{this.product.name}}</h2>
  
  <p class="product-description">
    {{html-safe this.product.description}}
  </p>
  
  {{#if this.isLoggedIn}}
    <div class="product-purchase">
      {{#if this.product.repurchaseable}}
        <LinkTo
          @route="subscribe.show"
          @model={{this.product.id}}
          class="btn btn-primary"
        >
          {{i18n "discourse_subscriptions.subscribe.title"}}
        </LinkTo>
  
        {{#if this.product.subscribed}}
          <LinkTo
            @route="user.billing.subscriptions"
            @model={{this.currentUser.username}}
            class="billing-link"
          >
            {{i18n "discourse_subscriptions.subscribe.view_past"}}
          </LinkTo>
        {{/if}}
      {{else}}
        {{#if this.product.subscribed}}
          <span class="purchased">
            &#x2713;
            {{i18n "discourse_subscriptions.subscribe.purchased"}}
          </span>
  
          <LinkTo
            @route="user.billing.subscriptions"
            @model={{this.currentUser.username}}
            class="billing-link"
          >
            {{i18n "discourse_subscriptions.subscribe.go_to_billing"}}
          </LinkTo>
        {{else}}
          <LinkTo
            @route="subscribe.show"
            @model={{this.product.id}}
            @disabled={{this.product.subscribed}}
            class="btn btn-primary"
          >
            {{i18n "discourse_subscriptions.subscribe.title"}}
          </LinkTo>
        {{/if}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "QIH4OmOa",
    "block": "[[[10,\"h2\"],[12],[1,[30,0,[\"product\",\"name\"]]],[13],[1,\"\\n\\n\"],[10,2],[14,0,\"product-description\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"product\",\"description\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isLoggedIn\"]],[[[1,\"  \"],[10,0],[14,0,\"product-purchase\"],[12],[1,\"\\n\"],[41,[30,0,[\"product\",\"repurchaseable\"]],[[[1,\"      \"],[8,[39,2],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@model\"],[\"subscribe.show\",[30,0,[\"product\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"discourse_subscriptions.subscribe.title\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"product\",\"subscribed\"]],[[[1,\"        \"],[8,[39,2],[[24,0,\"billing-link\"]],[[\"@route\",\"@model\"],[\"user.billing.subscriptions\",[30,0,[\"currentUser\",\"username\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,3],[\"discourse_subscriptions.subscribe.view_past\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"product\",\"subscribed\"]],[[[1,\"        \"],[10,1],[14,0,\"purchased\"],[12],[1,\"\\n          ✓\\n          \"],[1,[28,[35,3],[\"discourse_subscriptions.subscribe.purchased\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,2],[[24,0,\"billing-link\"]],[[\"@route\",\"@model\"],[\"user.billing.subscriptions\",[30,0,[\"currentUser\",\"username\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,3],[\"discourse_subscriptions.subscribe.go_to_billing\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@model\",\"@disabled\"],[\"subscribe.show\",[30,0,[\"product\",\"id\"]],[30,0,[\"product\",\"subscribed\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,3],[\"discourse_subscriptions.subscribe.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"html-safe\",\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/product-item.hbs",
    "isStrictMode": false
  });
  const ProductItem = dt7948.c(class ProductItem extends _component.default {}, [(0, _component2.classNames)("product")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProductItem);
});