define("discourse/plugins/discourse-subscriptions/discourse/templates/user/billing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "user-billing-page"}}
  
  <section class="user-secondary-navigation">
    <MobileNav
      @desktopClass="action-list nav-stacked"
      @currentPath={{this.router._router.currentPath}}
      class="activity-nav"
    >
      <li>
        <LinkTo @route="user.billing.subscriptions">
          {{i18n "discourse_subscriptions.navigation.subscriptions"}}
        </LinkTo>
      </li>
  
      <li>
        <LinkTo @route="user.billing.payments">
          {{i18n "discourse_subscriptions.navigation.payments"}}
        </LinkTo>
      </li>
    </MobileNav>
  </section>
  
  <section class="user-content">
    {{outlet}}
  </section>
  */
  {
    "id": "Q8ddiYLG",
    "block": "[[[1,[28,[35,0],[\"user-billing-page\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-secondary-navigation\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"activity-nav\"]],[[\"@desktopClass\",\"@currentPath\"],[\"action-list nav-stacked\",[30,0,[\"router\",\"_router\",\"currentPath\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\"],[\"user.billing.subscriptions\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"discourse_subscriptions.navigation.subscriptions\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\"],[\"user.billing.payments\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"discourse_subscriptions.navigation.payments\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"mobile-nav\",\"link-to\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/user/billing.hbs",
    "isStrictMode": false
  });
});